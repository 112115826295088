import React from 'react';

const LogoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="68.78px" viewBox="0 0 200 68.78">
    <path className="st0" d="M32.77,0c3.21,0.54,4.94,3.05,6.94,5.2c0.82,0.88,1.63,1.77,2.59,2.81c0.47-0.91,0.87-1.69,1.28-2.46
	c1.43-2.7,3.69-4.12,6.73-4.18c3.11-0.07,5.46,1.32,6.96,4.02c1.22,2.2,2.28,4.48,3.49,6.68c0.2,0.36,0.77,0.72,1.17,0.74
	c1.97,0.06,3.96-0.09,5.93,0c3.98,0.17,7.94-0.08,11.95,0.38c3.9,0.45,7.93-0.28,11.9-0.37c1.18-0.03,2.37,0.36,3.56,0.54
	c0.38,0.06,0.81,0.15,1.15,0.02c2.4-0.88,4.88-0.74,7.33-0.43c3.49,0.44,6.25,3.27,6.41,6.78c0.18,4.04,0.05,8.09,0.05,12.13
	c0,0.31-0.03,0.61-0.05,1.11c1.4,0,2.73,0.05,4.06-0.04c0.33-0.02,0.74-0.44,0.93-0.78c0.85-1.58,1.61-3.21,2.46-4.79
	c1.44-2.69,3.71-4.1,6.75-4.15c3.11-0.06,5.44,1.37,6.95,4.06c0.9,1.6,1.73,3.25,2.52,4.91c0.35,0.73,0.76,0.87,1.56,0.86
	c4.04-0.03,8.09-0.1,12.12,0.19c1.69,0.12,3.29,0.02,4.95-0.05c3.92-0.17,7.87,0.35,11.79,0.19c1.94-0.08,4.21,0.34,5.63-2.03
	c1.69-2.82,4.09-5.15,7.41-5.89c2.16-0.49,4.06-1.25,5.99-2.33c3.23-1.8,8.04-0.3,10.2,2.78c1.29,1.83,2.74,3.56,3.95,5.44
	c0.98,1.52,1.72,3.2,2.57,4.81c0,0.72,0,1.44,0,2.16c-0.85,3.68-4.03,5.71-6.1,8.54c-0.45,0.62-0.43,1.07-0.34,1.78
	c0.35,2.75,0.55,5.52,0.72,8.29c0.02,0.31-0.41,0.85-0.73,0.95c-0.76,0.22-1.58,0.34-2.37,0.31c-0.91-0.04-1.42-0.57-1.4-1.6
	c0.09-3.72,0-7.4-2.94-10.51c2.77-0.34,3.67-2.58,5.2-4.08c0.83-0.82,1.59-1.7,2.36-2.58c1.22-1.38,1.3-2.79,0.21-4.28
	c-1.64-2.24-3.29-4.48-4.97-6.7c-1.6-2.1-4.58-1.92-6.06,0.26c-0.26,0.38-0.89,0.65-1.37,0.7c-1.77,0.2-3.6-0.01-5.19,1.09
	c-2.56,1.77-4.33,4.11-5.24,7.05c-1.08,0-2.2-0.11-3.29,0.03c-0.99,0.13-1.92,0.61-2.9,0.81c-0.59,0.12-1.24,0.11-1.83-0.01
	c-0.98-0.21-1.93-0.74-2.9-0.8c-2.19-0.12-4.4-0.02-6.6-0.05c-1.5-0.02-2.74,0.42-3.61,1.93c-2.21-2.02-4.91-2.02-7.62-1.92
	c-2.59,0.1-5.19,0.23-7.77,0.41c-1.97,0.14-4.02-0.9-4.93-2.65c-0.91-1.75-1.83-3.5-2.7-5.27c-0.66-1.35-1.59-2.31-3.19-2.28
	c-1.59,0.03-2.5,0.99-3.1,2.39c-0.82,1.94-1.75,3.84-2.55,5.78c-0.45,1.1-1.16,1.62-2.35,1.61c-4.36-0.02-8.72-0.02-13.08,0
	c-0.85,0.01-1.42-0.24-1.49-1.14c-0.07-0.92-0.05-1.79,1.1-2.14c2.11-0.66,2.75-1.54,2.76-3.71c0.01-3.19,0.02-6.38,0-9.57
	c-0.02-2.36-1.19-3.53-3.57-3.61c-0.67-0.02-1.35,0.01-2.02-0.01c-1.91-0.06-3.31,0.66-3.87,2.6c-0.13-0.04-0.25-0.03-0.29-0.08
	c-1.31-2.13-3.25-2.71-5.65-2.54c-2.06,0.15-4.13,0.07-6.2,0.02c-1.8-0.04-3.27,0.42-4.12,2.51c-0.81-2.35-2.53-2.54-4.44-2.51
	c-3.95,0.05-7.91-0.02-11.86,0.06c-1.05,0.02-2.16,0.23-3.11,0.66c-1.15,0.52-1.79,0.19-2.3-0.78c-1.21-2.3-2.39-4.62-3.58-6.93
	c-0.41-0.8-0.82-1.6-1.22-2.4c-0.64-1.26-1.6-2.06-3.07-2.03c-1.4,0.03-2.32,0.79-2.93,2.02c-0.91,1.85-1.83,3.7-2.81,5.51
	c-0.39,0.72-0.98,1.34-1.58,2.15c-0.82-0.88-1.49-1.58-2.15-2.3c-2.31-2.51-4.67-4.98-6.91-7.57C32.9,4.41,31.62,3.85,30,4.35
	C28.4,4.85,27.45,5.97,27.29,8c-2,0-4.02,0.09-6.02-0.02c-6.04-0.34-10,2.74-12.87,7.68c-0.71,1.22-1.64,1.43-2.99,0.64
	c-1.34-0.79-1.65-1.72-0.95-2.94c2.86-4.97,6.78-8.63,12.6-9.66c1.97-0.35,4.03-0.15,6.04-0.33c0.69-0.06,1.37-0.46,2.02-0.78
	c1.75-0.85,3.48-1.73,5.22-2.6C31.15,0,31.96,0,32.77,0z M104.65,35.56c0.06,0.1,0.11,0.2,0.17,0.29c3.81,0,7.61,0.03,11.41-0.04
	c0.37-0.01,0.88-0.53,1.08-0.94c0.9-1.86,1.74-3.74,2.54-5.64c0.86-2.05,2.4-3.16,4.56-3.21c2.11-0.05,3.66,1.01,4.62,2.93
	c0.88,1.77,1.79,3.52,2.7,5.27c0.86,1.65,2.23,2.27,4.02,1.89c0.57-0.12,1.15-0.23,1.72-0.23c2.7-0.02,5.4-0.1,8.09,0.03
	c1.36,0.06,2.7,0.54,4.05,0.79c0.39,0.07,0.83,0.06,1.21-0.02c1.07-0.24,2.12-0.69,3.2-0.77c1.79-0.13,3.59-0.06,5.39-0.03
	c1.69,0.03,3.43-0.3,4.97,0.77c0.16,0.11,0.49-0.02,0.74-0.07c1.26-0.23,2.51-0.6,3.77-0.67c1.04-0.05,1.64-0.3,2.2-1.28
	c2.27-3.91,5.08-7.14,10.22-6.88c0.42,0.02,0.97-0.38,1.3-0.74c2.31-2.53,6.44-2.33,8.49,0.44c1.62,2.2,3.26,4.39,4.89,6.59
	c1.6,2.17,1.44,4.67-0.36,6.63c-1.97,2.14-3.91,4.3-5.91,6.51c2.13,2.71,1.51,6.09,2.18,9.31c0.38-0.26,0.75-0.61,0.72-0.91
	c-0.26-2.35-0.64-4.69-0.88-7.04c-0.07-0.69-0.07-1.6,0.31-2.09c1.28-1.67,2.69-3.25,4.14-4.78c2.51-2.66,2.7-5.61,0.5-8.49
	c-1.6-2.11-3.17-4.24-4.73-6.37c-1.67-2.28-5.56-3.63-7.81-2.14c-2.08,1.38-4.26,1.98-6.59,2.55c-3,0.73-4.95,3.03-6.53,5.53
	c-0.92,1.45-1.92,2.28-3.69,2.06c-0.51-0.06-1.04,0.24-1.57,0.28c-0.69,0.06-1.39,0.06-2.08,0.01c-0.58-0.04-1.14-0.3-1.71-0.3
	c-2.88-0.03-5.81-0.37-8.61,0.09c-2.43,0.4-4.69,0.13-7.06-0.03c-3.8-0.26-7.64-0.08-11.45-0.01c-1.15,0.02-1.84-0.37-2.33-1.4
	c-0.83-1.74-1.72-3.45-2.63-5.15c-1.16-2.17-2.99-3.37-5.45-3.33c-2.44,0.04-4.27,1.22-5.37,3.44c-0.84,1.69-1.62,3.41-2.59,5.02
	c-0.36,0.6-1.15,1.21-1.82,1.3c-1.46,0.19-2.96,0.1-4.44,0.05c-1.21-0.04-1.9-0.87-1.87-2.01c0.09-4.04,0.21-8.07,0.22-12.11
	c0-3.06-2.18-5.68-5.16-5.97c-2.25-0.22-4.57-0.48-6.77,0.54c-0.42,0.2-1.04,0.05-1.55-0.02c-1.18-0.18-2.36-0.55-3.54-0.59
	c-2.69-0.09-5.39-0.05-8.09-0.01c-0.71,0.01-1.41,0.24-2.12,0.27c-0.86,0.04-1.73,0.02-2.6-0.03c-0.49-0.03-0.97-0.24-1.45-0.24
	c-5.17-0.01-10.34-0.03-15.5,0.05c-1.26,0.02-1.99-0.36-2.53-1.51c-1.09-2.31-2.27-4.58-3.48-6.84c-1.11-2.08-2.93-3.18-5.26-3.17
	c-2.38,0-4.22,1.1-5.36,3.25C44.79,7,44.46,7.69,44.1,8.36c-0.89,1.67-2.12,1.85-3.42,0.47c-1.64-1.73-3.14-3.6-4.88-5.22
	c-0.87-0.81-2.08-1.46-3.23-1.7c-2.16-0.44-4.31,0.13-5.86,1.7c-1.29,1.31-2.68,1.63-4.35,1.51c-0.54-0.04-1.08,0.03-1.62,0.01
	c-5.89-0.2-10.21,2.58-13.56,7.17c-0.37,0.51-0.74,1.03-1,1.6c-0.11,0.23,0.07,0.59,0.11,0.9c0.25-0.11,0.55-0.16,0.72-0.33
	c0.24-0.25,0.38-0.61,0.58-0.9c2.58-3.78,5.82-6.65,10.56-7.18c1.96-0.22,3.95-0.17,5.92-0.13c1.08,0.02,1.74-0.23,2.36-1.25
	c1.92-3.18,6.68-3.32,8.65-0.72c1.65,2.17,3.66,4.07,5.52,6.09c0.75,0.81,1.5,1.61,2.38,2.55c1.05-2.06,2.03-3.91,2.94-5.8
	c0.91-1.89,2.42-2.91,4.46-2.95c2.12-0.04,3.66,1.02,4.62,2.93c1.31,2.61,2.63,5.2,3.96,7.8c0.26,0.5,0.57,0.97,0.78,1.32
	c1.15-0.21,2.11-0.52,3.07-0.53c4.67-0.05,9.35-0.08,14.02,0.02c1.06,0.02,2.11,0.85,3.16,0.84c1.1-0.01,2.19-0.78,3.3-0.84
	c2.6-0.14,5.21-0.01,7.82-0.06c1.74-0.03,3.42,0.04,4.75,1.46c1.91-1.95,4.26-1.42,6.47-1.43c3.04-0.02,5.07,2.03,5.09,5.05
	c0.02,3.01-0.07,6.03,0.03,9.03C107.57,32.21,107.07,34.31,104.65,35.56z"/>
<path d="M192.62,38.35c-1.86,1.84-3.72,3.68-5.58,5.51c-0.11-0.06-0.21-0.12-0.32-0.18c0-1.18,0-2.35,0-3.67
	c-1.7,0-3.28-0.16-4.8,0.08c-0.64,0.1-1.52,0.98-1.64,1.62c-0.48,2.61-0.2,5.1,2.9,6.21c2.59,0.93,3.93,3.14,3.94,6
	c0,1.12,0.03,2.24,0.02,3.36c-0.04,3.08-1.47,5.39-4.03,7c-0.49,0.31-1.18,0.41-1.78,0.43c-2,0.05-4.01,0.02-6.13,0.02
	c0,1.39,0,2.63,0,3.86c-0.12,0.06-0.24,0.13-0.35,0.19c-1.86-2.35-3.72-4.7-5.65-7.13c1.95-2.37,3.82-4.63,5.93-7.19
	c0,1.78,0,3.17,0,4.72c1.65,0,3.17,0.1,4.66-0.06c0.48-0.05,1.14-0.68,1.28-1.17c0.41-1.43,0.57-2.92-0.71-4.11
	c-0.39-0.37-0.75-0.78-1.19-1.09c-2.73-1.96-4.36-4.53-4.18-7.95c0.12-2.16,0.27-4.39,0.89-6.45c0.91-3.04,3.25-4.45,6.4-4.43
	c1.42,0.01,2.85,0,4.38,0c0-0.79,0-1.48,0-2.54c2.13,2.4,4.03,4.55,5.94,6.7C192.62,38.17,192.62,38.26,192.62,38.35z M21.4,12.36
	c-2.2,0.03-4.34,0.52-6.03,1.98c-5.02,4.35-6.48,9.86-5.15,16.26c1.28,6.18,5.27,9.52,11.2,10.9c0.17,0.04,0.36,0.04,0.53,0.1
	c2.2,0.76,4.44,1.43,6.57,2.34c1.49,0.63,2.15,2.17,1.93,3.66c-0.21,1.42-1.21,2.41-2.96,2.65c-1.91,0.26-3.83,0.47-5.75,0.5
	c-3.59,0.05-7.18-0.05-10.77-0.09c-0.3,0-0.61-0.05-1.06-0.1c0-2.2,0-4.34,0-6.73C6.49,47.46,3.27,50.88,0,54.35
	c3.27,3.68,6.51,7.32,9.75,10.96c0.09-0.06,0.19-0.11,0.28-0.17c0-2.04,0-4.09,0-6.28c0.62,0,1.15,0,1.68,0
	c5.25-0.03,10.5,0.02,15.75-0.11c6.53-0.16,10.24-4.22,11.49-8.81c1.7-6.23-0.34-14.62-9.62-16.23c-2.16-0.38-4.29-0.93-6.43-1.43
	c-2.73-0.63-4.19-2.28-4.26-4.78c-0.07-2.62,1.68-5.45,4.14-5.76c2.76-0.35,5.6-0.08,8.55-0.08c0,1.94,0,3.82,0,5.92
	c3.42-2.99,6.65-5.82,9.92-8.69c-3.3-3.59-6.52-7.09-9.98-10.86c0,1.62,0,2.86,0,4.31C27.85,12.35,24.63,12.32,21.4,12.36z
	 M52.81,41.93c-1.7,0-3.4,0-5.11,0c-0.13,0-0.27,0.03-0.4,0.05c-2.12,0.34-3.07,1.42-3.08,3.51c0,1.34,0,2.68,0,4.02
	c0,2.87,1.15,4.05,4.04,4.08c2.24,0.03,4.48,0.02,6.72,0c1.16-0.01,2.34,0.04,3.48-0.14c1.94-0.3,2.78-1.38,2.8-3.3
	c0.01-1.47,0.01-2.95,0-4.42c-0.01-2.5-1.11-3.69-3.62-3.8C56.04,41.86,54.43,41.92,52.81,41.93
	C52.81,41.92,52.81,41.92,52.81,41.93z M50.17,46.5c0.15-0.83,0.23-2.27,0.6-2.35c1.26-0.29,2.64-0.15,3.96-0.05
	c0.21,0.02,0.51,0.7,0.52,1.09c0.06,1.7,0.03,3.4,0.02,5.1c0,0.13,0.03,0.29-0.02,0.4c-0.8,1.75-2.36,0.71-3.56,0.92
	c-0.81,0.14-1.53-0.17-1.53-1.22c0-0.9,0-1.79,0-2.69C50.15,47.3,50.09,46.89,50.17,46.5z M141.43,41.92c-1.7,0-3.41-0.07-5.11,0.02
	c-2.45,0.12-3.55,1.32-3.55,3.73c0,1.3,0,2.59,0,3.89c0,2.87,1.13,4.01,4.05,4.04c2.51,0.03,5.02,0.02,7.53,0
	c0.89-0.01,1.8,0.01,2.67-0.14c1.92-0.34,2.76-1.4,2.76-3.33c0.01-1.43,0-2.86,0-4.29c0-2.63-1.14-3.83-3.79-3.9
	C144.47,41.88,142.95,41.91,141.43,41.92C141.43,41.92,141.43,41.92,141.43,41.92z M138.68,45.33c-0.06-1.07,0.48-1.45,1.45-1.46
	c4.32-0.06,3.64-0.05,3.67,3.43c0.01,0.9,0,1.79,0,2.69c0,0.22,0.06,0.48-0.02,0.67c-0.82,1.79-2.42,0.76-3.67,0.96
	c-0.86,0.14-1.46-0.29-1.42-1.31c0.03-0.85,0.01-1.7,0.01-2.55C138.69,46.94,138.73,46.13,138.68,45.33z M76.11,21.76
	c-3.99,0.08-7.99,0.01-11.98,0.04c-0.8,0.01-1.62,0.1-2.39,0.31c-1.2,0.33-1.87,1.1-1.8,2.46c0.08,1.38,0.02,2.77,0.02,4.16
	c0,2.59,0.78,3.43,3.4,3.51c2.02,0.06,4.05-0.13,6.05,0.06c1.63,0.16,3.14-0.63,4.85-0.13c1.43,0.41,3.08,0.09,4.65,0.09
	c0-2.16,0-4.15,0-6.16c-3.54,0-6.98,0-10.49,0c0,0.54,0,1,0,1.55c1.15,0.05,2.24,0.1,3.38,0.15c0,0.6-0.01,1.04,0,1.48
	c0.03,0.97-0.62,1.33-1.39,1.23c-1.23-0.17-2.75,0.76-3.62-0.84c-0.11-0.21-0.04-0.53-0.04-0.8c0-1.16,0-2.33,0-3.49
	c0.01-1.63,0.19-1.82,1.88-1.82c3.05-0.01,6.1,0,9.24,0C77.93,22.18,77.39,21.74,76.11,21.76z M93.61,22.62
	c-0.3-0.34-0.75-0.78-1.14-0.79c-2.86-0.07-5.72-0.04-8.61-0.04c0,3.57,0,6.98,0,10.34c1.15,0,2.2,0,3.36,0c0-2.76,0-5.41,0-8.3
	c1.82,2.14,3.59,3.99,5.07,6.05c1.33,1.85,2.86,2.67,5.17,2.41c1.84-0.21,3.71-0.04,5.57-0.04c0-3.6,0-6.97,0-10.38
	c-1.06,0-2.02,0-3.07,0c0,2.67,0,5.25,0,8.2C97.67,27.39,95.66,24.99,93.61,22.62z M157.33,44.52c1.8,2.72,3.59,5.44,5.42,8.14
	c0.25,0.37,0.68,0.87,1.04,0.89c2.36,0.08,4.72,0.04,7.11,0.04c0-3.97,0-7.75,0-11.58c-0.9,0-1.72,0-2.71,0c0,2.99,0,5.88,0,9.09
	c-1.95-2.94-3.69-5.6-5.47-8.23c-0.25-0.38-0.67-0.9-1.03-0.91c-2.49-0.08-4.99-0.04-7.52-0.04c0,3.96,0,7.74,0,11.58
	c0.98,0,1.89,0,2.89,0c0-3.03,0-5.97,0-8.91C157.14,44.57,157.23,44.55,157.33,44.52z M82.43,49.75c0,2.55,1.13,3.74,3.73,3.83
	c1.84,0.06,3.68,0.01,5.52,0.01c1.3,0,2.6,0.04,3.9-0.02c2.46-0.12,3.56-1.29,3.56-3.71c0-0.72,0-1.43,0-2.15c0-1.9,0-3.81,0-5.74
	c-0.98,0-1.77,0-2.72,0c0,2.65,0,5.23,0,7.81c0,1.61-0.23,1.84-1.88,1.86c-1.3,0.01-2.6,0.01-3.9,0c-1.81-0.01-2-0.2-2-2
	c0-2.53,0-5.07,0-7.67c-2.12,0-4.07,0-6.22,0C82.43,44.65,82.43,47.2,82.43,49.75z M71.89,51.7c0-3.38,0-6.53,0-9.72
	c-2.11,0-4.07,0-6.08,0c0,3.88,0,7.7,0,11.62c3.87,0,7.63-0.06,11.39,0.03c1.34,0.03,2.06-0.31,2.03-1.93
	C76.88,51.7,74.46,51.7,71.89,51.7z M118.17,43.5c-0.46-0.53-0.9-1.51-1.37-1.52c-4.57-0.12-9.15-0.07-13.81-0.07
	c-0.16,0.63-0.31,1.21-0.52,1.98c1.7,0,3.21,0,4.8,0c0,3.27,0,6.4,0,9.61c2,0,3.9,0,5.95,0c0-3.23,0-6.38,0-9.66
	c1.65,0,3.16,0,4.67,0C117.99,43.73,118.08,43.61,118.17,43.5z M47.38,32.19c2.5,0,4.93,0,7.42,0c0-3.47,0-6.87,0-10.34
	c-2.5,0-4.9,0-7.42,0C47.38,25.37,47.38,28.74,47.38,32.19z M128.23,41.99c-2.18,0-4.27,0-6.42,0c-0.02,0.47-0.06,0.86-0.06,1.26
	c0,3.39,0,6.79,0,10.26c2.21,0,4.29,0,6.49,0C128.23,49.6,128.23,45.82,128.23,41.99z M134.9,67.71c1.29,0,2.47,0,3.76,0
	c0-1.26,0-2.41,0-3.63c0.93,0,1.74,0,2.71,0c0,1.29,0,2.47,0,3.63c1.34,0,2.53,0,3.7,0c0-3,0-5.89,0-8.82c-1.27,0-2.46,0-3.81,0
	c0,1.22,0,2.36,0,3.47c-0.95,0-1.74,0-2.64,0c0-1.21,0-2.31,0-3.48c-1.31,0-2.49,0-3.72,0C134.9,61.87,134.9,64.79,134.9,67.71z
	 M124.37,58.81c-0.09,0-0.18,0-0.27,0c-1.4,0.1-2.25,0.92-2.29,2.3c-0.04,1.48-0.04,2.95,0,4.43c0.04,1.34,0.83,2.2,2.18,2.25
	c1.97,0.07,3.95,0.07,5.92,0c1.41-0.05,2.18-0.91,2.22-2.36c0.04-1.43,0.04-2.86,0-4.3c-0.05-1.49-0.87-2.28-2.38-2.33
	c-0.9-0.03-1.79-0.01-2.69-0.01C126.17,58.81,125.27,58.8,124.37,58.81z M128.51,64.53c0,1.74-0.17,1.86-1.93,1.8
	c-0.87-0.02-1.21-0.39-1.19-1.22c0.02-1.2,0.03-2.41,0-3.61c-0.04-1.35,0.9-1.16,1.75-1.19c0.92-0.04,1.47,0.22,1.38,1.28
	c-0.05,0.58-0.01,1.16-0.01,1.74C128.51,63.73,128.51,64.13,128.51,64.53z M103.95,67.81c1.45-0.08,2.26-0.87,2.3-2.27
	c0.05-1.48,0.04-2.95,0-4.43c-0.04-1.43-0.85-2.25-2.27-2.29c-1.93-0.05-3.86-0.05-5.78,0c-1.39,0.04-2.21,0.93-2.25,2.33
	c-0.03,1.43-0.03,2.86,0,4.3c0.03,1.48,0.84,2.29,2.36,2.36c0.94,0.05,1.88,0.01,2.82,0.01C102.06,67.82,103.01,67.86,103.95,67.81z
	 M102.63,64.49c0,1.73-0.78,2.33-2.41,1.77c-0.31-0.11-0.64-0.63-0.66-0.98c-0.08-1.29-0.02-2.59-0.04-3.88
	c-0.02-1.16,0.79-1.09,1.57-1.09c0.78,0,1.6-0.08,1.55,1.1c-0.03,0.62,0,1.25,0,1.87C102.63,63.69,102.63,64.09,102.63,64.49z
	 M157.11,58.81c-1.57,0.05-2.42,0.87-2.45,2.41c-0.03,1.39-0.03,2.77,0,4.16c0.03,1.52,0.79,2.37,2.29,2.42
	c1.93,0.06,3.86,0.06,5.78,0c1.37-0.04,2.18-0.86,2.22-2.2c0.05-1.52,0.05-3.04,0-4.56c-0.04-1.34-0.86-2.15-2.21-2.22
	c-0.94-0.05-1.88-0.01-2.82-0.01C159,58.81,158.05,58.78,157.11,58.81z M161.36,64.5c0,1.71-0.81,2.32-2.43,1.76
	c-0.29-0.1-0.63-0.55-0.65-0.86c-0.07-1.38-0.06-2.76-0.02-4.15c0.04-1.19,0.98-0.91,1.7-0.94c0.69-0.03,1.4-0.03,1.4,0.97
	c0,0.67,0,1.34,0,2.01C161.36,63.7,161.36,64.1,161.36,64.5z M112.79,60.33c1.48,0,2.89,0,4.48,0c-0.16-0.62-0.27-1.06-0.38-1.46
	c-2.7,0-5.24,0-7.87,0c0,2.98,0,5.9,0,8.89c1.24,0,2.38,0,3.71,0c0-1.28,0-2.5,0-3.87c1.5,0,2.82,0,4.22,0
	c-0.02-0.53-0.04-0.91-0.06-1.4c-1.37,0-2.66,0-4.1,0C112.79,61.68,112.79,61.08,112.79,60.33z M151.87,58.89c-1.33,0-2.56,0-3.86,0
	c0,2.97,0,5.85,0,8.84c1.3,0,2.57,0,3.86,0C151.87,64.73,151.87,61.81,151.87,58.89z M47.9,16.74c2.24,0,4.27,0,6.47,0
	c-1.09-2.14-2.11-4.14-3.26-6.37C49.97,12.64,48.95,14.66,47.9,16.74z M128.38,38.52c-1.12-2.16-2.17-4.17-3.32-6.4
	c-1.16,2.28-2.17,4.28-3.25,6.4C124.04,38.52,126.09,38.52,128.38,38.52z M149.96,54.09c-0.54,1.37-0.96,2.44-1.43,3.62
	c1.01,0,1.88,0,2.99,0C150.99,56.47,150.53,55.41,149.96,54.09z"/>
  </svg>
);

export default LogoIcon;
